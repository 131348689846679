@font-face {
  font-family: myfont;
  src: url(../Fonts/Cristola\ Display.otf);
}

@font-face {
  font-family: bodyfont;
  src: url(../Fonts/antebas\ medium.otf);
}

.gallery-container {
  text-align: center;
}

.gallery-filter {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.gallery-filter button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #ffffff;
  color: #333;
  font-size: 16px;
  font-family: bodyfont;
}

.gallery-filter button.active {
  background-color: #ffffff;
  color: #000000;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 5px;
  /* padding: 20px; */
}

.gallery-item {
  position: relative;
  width: 100%;
  padding-bottom: 75%; /* Maintain aspect ratio */
  overflow: hidden;
}

.gallery-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the container */
}
.gallery-filter button.active {
  background-color: #ffffff;
  color: #000000;
  font-weight: bold; /* Make the font bold */
  border-bottom: 2px solid #000000; /* Add a bottom border */
  
}
/* Responsive styles for screen widths up to 768px */
@media only screen and (max-width: 1400px) {
  /* .gallery-grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  } */
  .gallery-filter {
    justify-content: center;
    flex-wrap: wrap;
}
  
}
@media only screen and (max-width: 900px) {
  .gallery-grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .gallery-filter {
    justify-content: center;
    flex-wrap: wrap;
}
  .gallery-filter button {
    padding: 5px 5px;
    font-size: 12px;
   
}
}
@media only screen and (max-width: 768px) {
  .gallery-grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .gallery-filter button {
    padding: 5px 5px;
    font-size: 12px;
}
}

/* Responsive styles for screen widths up to 500px */
@media only screen and (max-width: 500px) {
  .gallery-filter {
    justify-content: center;
    flex-wrap: wrap;
}
.gallery-filter button {
    margin: 5px;
    padding: 5px 10px;
    font-size: 14px;
}
.gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
}
}
