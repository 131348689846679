*{
    padding: 0;
    margin: 0;
}
.inner-container-interiorexterior2{
    width: 99%;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    margin: 0 auto;
    /* padding: 10px; */
}
.interiorexterior2-container2{
    padding-top: 10px;
    padding-bottom: 10px;
    width: 85%;
    margin: 0 auto;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: invert(100%);
}

.carousel img {
  margin: 0 auto;
  max-width: 100%;
}
@media only screen and (max-width:500px){
    .interiorexterior2-headtext h3{
           font-size: 14px;
    }
}