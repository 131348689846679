/* src/components/Carousel.css */

.landing-page {
    height: 100vh;
    position: relative;
    width: 100%;
  }
  /* Carousel.css */

.first-slide-active .navbar-landing,
.first-slide-active .language-options a,
.first-slide-active .social-media-icons a,
.first-slide-active .navbar-logo a,
.first-slide-active .navbar-links a,
.first-slide-active .navbar-toggle i{
  color: #000000; /* Change to the color you want */
}

.first-slide-active .carousel-control-prev-icon,
.first-slide-active .carousel-control-next-icon {
  filter: invert(1); /* Example to change the color of the carousel controls */
}
.tamil-language .navbar-links a{
  font-size: 13px;
}
.tamil-language .navbar-container{
  align-items: start;
}
/* Add more styles as needed */

  .navbar-landing{
    position: absolute;
  }
  .carousel-inner {
    height: 100vh;
  }
  .navbar-logo{
    width: 37%;
  }
  .carousel-item {
    height: 100vh;
    position: relative;
  }
  
  .carousel-item img {
    height: 100vh;
    object-fit: cover;
  }
  
  .carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(218, 218, 218, 0.336); */
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: white; */
    text-align: center;
    padding: 20px;
  }
  
  .carousel-content {
    z-index: 2; /* Ensure the content is above the overlay */
  }
  
  .language-options {
    position: absolute;
    bottom: 20px;
    right: 1%;
    transform: translateX(-50%);
    color: white;
    text-align: center;
    z-index: 3; /* Ensure the language options are above other elements */
  }
  
  .language-options a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
    font-size: 16px;
  }
  .row{
    /* border: 1px solid black; */
  }
 .name-col{
  border: 1px solid red;

 }
 .social-media-icons{
  position: absolute;
  bottom: 20px;
  left: 8%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  z-index: 3; 
 }
 .social-media-icons a{
  color: white;
  margin: 0 10px;
  text-decoration: none;
  font-size: 20px;
 }
 @media screen and (min-width:1800px) and (max-width:2560px) {
  .social-media-icons {
    left: 5%;
  }
 }
 @media screen and (max-width:500px) {
   .landing-page{
    height: 100vh;
   }
   .social-media-icons a{
   font-size: 14px;
   }
   .social-media-icons{
       left: 25%;
   }
   .language-options{
    right: -5%;
    
   }
 }