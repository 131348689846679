/* /=============== GOOGLE FONTS ===============/ */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"); */
/* /=============== VARIABLES CSS ===============/ */
:root {
  --first-color: hsl(222, 80%, 58%);
  --dark-color: hsl(222, 24%, 8%);
  --title-color: hsl(222, 8%, 8%);
  --text-color-light: hsl(222, 8%, 65%);
  --white-color: #fff;
  --body-color: hsl(222, 100%, 99%);
  --body-font: "Poppins", sans-serif;
  --small-font-size: .813rem;
}

@media screen and (min-width: 968px) {
  :root {
    --small-font-size: .875rem;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.search-container
{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    margin-right: 30px;
}
.search {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: var(--white-color);
  box-shadow: 0 4px 24px hsla(222, 68%, 12%, 0.1);
  border-radius: 4rem;
  padding: 10px;
  overflow: hidden;
  transition: width 0.5s cubic-bezier(0.9, 0, 0.3, 0.9);
  margin-left: 140vh;
  /* margin-bottom: -40px;
  margin-top: 100px; */
  z-index: 2;
}

.search__input {
  border: none;
  outline: none;
  width: calc(100% - 64px);
  height: 100%;
  border-radius: 4rem;
  padding-left: 14px;
  font-family: var(--body-font);
  font-size: var(--small-font-size);
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1.5s;
}

.search__input:-webkit-autofill {
  box-shadow: 0 0 0 100px var(--white-color) inset;
}

.search__button {
  width: 56px;
  height: 56px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4px;
  margin: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: transform 0.6s cubic-bezier(0.9, 0, 0.3, 0.9);
}

.search_icon, .search_close {
  color: black;
  font-size: 3rem;
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.9, 0, 0.3, 0.9);
}
.search__icon{
    width: 20px;
    height: auto;
    margin-top: 10px;
    margin-left: 15px;
}

.search__close {
  opacity: 0;
  width: 40px;
  height: auto;
  margin-top: -30px;
  
}

.show-search {
  width: 100%;
}

.show-search .search__input {
  opacity: 1;
  pointer-events: initial;
}

.show-search .search__button {
  transform: rotate(90deg);
}

.show-search .search__icon {
  opacity: 0;
  
}

.show-search .search__close {
  opacity: 1;
}
.dropdown-list {
    position: absolute;
    top: 150px; /* Adjust based on your layout */
    /* right: 70px; */
    width: 20%;
    max-height: 200px;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.815);
    border: 1px solid #ccc;
    border-radius: 5px;
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0;
    z-index: 3;
  }
  .dropdown-list::-webkit-scrollbar{
    display: none;
  }
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-filter-name {
    color: #333;
    text-decoration: none;
  }
  
  .dropdown-filter-name:hover {
    color: black;
  }
  
@media screen and (max-width:2560px) {
  .search {
    margin-left: 180vh;
  }
  .dropdown-list{
    width: 13%;
  }
}
@media screen and (max-width:2400px) {
  .search {
    margin-left: 160vh;
  }
  /* .dropdown-list{
    width: 12%;
  } */
}
@media screen and (max-width:2300px) {
  .search {
    margin-left: 160vh;
  }
  /* .dropdown-list{
    width: 18%;
  } */
}
@media screen and (max-width:2200px) {
  .search {
    margin-left: 160vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:2100px) {
  .search {
    margin-left: 150vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:2000px) {
  .search {
    margin-left: 150vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:1920px) {
  .search {
    margin-left: 145vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:1800px) {
  .search {
    margin-left: 140vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:1728px) {
  .search {
    margin-left: 140vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:1700px) {
  .search {
    margin-left: 180vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:1600px) {
  .search {
    margin-left: 168vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:1560px) {
  .search {
    margin-left: 160vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:1536px) {
  .search {
    margin-left: 157vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:1500px) {
  .search {
    margin-left: 155vh;
  }
  .dropdown-list{
    width: 18%;
  }
}
@media screen and (max-width:1440px) {
  .search {
    margin-left: 150vh;
  }
  .dropdown-list{
    width: 20%;
  }
}

@media screen and (max-width: 576px) {
  .search {
    margin-left: 0vh;
  }
  .dropdown-list{
    right: 0;
    left: 10px;
    width: 300px;
    top: 120px;
  }
}
@media screen and (min-width: 576px) {
  .show-search {
    width: 350px;
  }
  
}
