*{
    padding: 0;
    margin: 0;
}
@font-face {
    font-family: myfont;
    src: url(../../../Fonts/Cristola\ Display.ttf);
}
@font-face {
    font-family: bodyfont2;
    src: url(../../../Fonts/DMSans-Medium.ttf);
}
.inner-container-Artisanaldesign2{
    margin: 0 auto;
    width: 99%;
}
.Artisanaldesign-container2-col1{

    font-size: 50px;
    font-weight: 500;
}
.Artisanaldesign-container2-col2{
    text-align: end;
}
.Artisanaldesign-container2-col2 img{
    width: 40%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 30px;
}
.inner-container-Artisanaldesign2{
    border-bottom: 2px solid black;
}
.inner-container-Artisanaldesign3{
    margin: 0 auto;
    width: 99%;
}
.Artisanaldesign-container3-col1{
    /* border: 2px solid black; */
}
.Artisanaldesign-container3-col1 img{
    margin-top: 18px;
    width: 100%;
}
.Artisanaldesign-container3-col2 img{
    width: 80%;
 
}
.Artisanaldesign-container3-col2 p{
         text-align: justify;
}
.Artisanaldesign-container3-col1{
    text-align: end;
}
.inner-head-avinashi{
    word-spacing: 10px;
}
.journal-para{
    font-family: bodyfont2;
}
/* ........................................................... Media Query ......................................  */
@media only screen and (min-width: 2000px) and (max-width: 2560px){
    .Artisanaldesign-container2-col1{
        font-family: myfont;
        font-size: 125px;
        margin-top: auto;
    }
    .tamil-language .Artisanaldesign-container2-col1{
        font-size: 80px;
    }
}
/* @media only screen and (min-width:200px){
    .Artisanaldesign-container2-col1{
        font-family: myfont;
        font-size: 125px;
        margin-top: auto;
    }
    .tamil-language .Artisanaldesign-container2-col1{
        font-size: 50px;
    }
} */
@media only screen and (min-width: 1600px) and (max-width:2000px){
    .Artisanaldesign-container2-col1{
        font-family: myfont;
        font-size: 80px;
        margin-top: auto;
       word-spacing: 10px;
    }
    .tamil-language .Artisanaldesign-container2-col1{
        font-size: 50px;
    }
}
@media only screen and (min-width:821px) and (max-width:1100px){
    .Artisanaldesign-container2-col2 img{
        width: 80%;
    }
    .Artisanaldesign-container2-col1{
              font-size: 60px;
    }
    .Artisanaldesign-container2-col2 img{
              width: 100%;
    }
}
@media only screen and (max-width:500px){
    .Artisanaldesign-container2-col1{
        font-size: 27px;
    }
    .Artisanaldesign-container2-col2 img{
        width: 90%;
    }
    .Artisanaldesign-container3-col2 p{
        font-size: 14px;
    }
}