*{
    padding: 0;
    margin: 0;
}
@font-face {
    font-family: myfont;
    src: url(../../../Fonts/Cristola\ Display.otf);
}
@font-face {
    font-family: bodyfont;
    src: url(../../../Fonts/antebas\ medium.otf);
}
.project-custom-navbar .navbar-logo a,
.project-custom-navbar .navbar-links a,
.project-custom-navbar .navbar-toggle i {
  color: #000000; /* Change to your desired color */
}
.project-img{
    width: 400px;
}
.detail-page{
    width: 100%;
    height: 90vh;
    /* background-color: green; */
    text-align: start;
 
}

.Summerhouse1{
    width: 550px;
}
.Summerhouse2{
    float: right;
    width: 805px;
    margin-left: 0px;
}
.second-container-detailpage-summerhouse{
    width: 100%;
    height: 100vh;
    background-image: url('../DetailProjectpageImages/summerhousekey.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 30px;
}
.second-container-detailpage img{
   width: 100%;
}
.detail-page-contain3{
    width: 100%;
    height: 60vh;
    padding: 0;
    margin-top: 20px;
}
.Summerhouse4{
    width: 545px;
}
.Summerhouse5{
    width: 225px;
    margin-left: 20px;
}
.Summerhouse6{
    width: 539px;
    margin-left: 20px;
}
.info-contain{
    /* background-color: gray; */
}
.detailproject-col{
    /* border: 2px solid rgb(0, 0, 0); */
    margin-left: 250px;
    font-family: bodyfont;
}
.detailproject-col h2{
    margin-top: 10px;
    font-size: 25px;
}
.detailproject-col h6{
    margin-top: 15px;
    font-size: 15px;
}
.detailproject-col p{
    margin-top: 15px;
    font-size: 14px;
    font-weight: 100;
}
.otherprojects-contain{
    width: 100%;
    height: 51vh;
    margin-top: 100px;
    /* background-color: green; */
}
.plus-img{
    width: 30px;
    margin-right: 10px;
    /* margin-left: 50px; */
}

.other-head{
    padding-left: 80px;
    font-size: 30px;
    font-weight: 400;
    font-family: bodyfont;
}
.otherproject-row{
    /* border: 1px solid red; */
}
.otherprojects{
    /* border: 1px solid black; */
    text-align: center;
}
.otherprojects img{
  width: 380px;
}
.slide-image-container{
    /* text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; */
    margin-top: 20px;
}


/* ...................................... Column method .....................................  */
/* .detailpage-row3{

}
.third-contain-first-col{
    height: 60vh;
    background-image: url(./Detailprojectimages/Summerhouse4.JPG);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
.third-contain-second-col{
    height: 60vh;
    background-image: url(./Detailprojectimages/Summerhouse5.png);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;

 
}
.third-contain-third-col{
    height: 60vh;
    background-image: url(./Detailprojectimages/Summerhouse6.png);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
  
} */
/* ................................................ Media Query ............................................... */
@media only screen and (max-width:2560px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 955px;
   }
   .Summerhouse2{
     width: 1535px;
   }
    .second-container-detailpage-Summerhouse{
        height: 150vh;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 1045px;
    }
    .Summerhouse5{
        width: 435px;
    }
    .Summerhouse6{
        width: 1040px;
    }
    .detailproject-col{
        margin-left: 400px;
    }
    .otherprojects .other-project-img{
        width: 600px;
        height: 400px;
    }
}
@media only screen and (max-width:2500px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     max-width:950px;
   }
   .Summerhouse2{
     width: 1530px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 980px;
    }
    .Summerhouse5{
        width: 405px;
    }
    .Summerhouse6{
        width: 970px;
    }
    .detailproject-col{
        margin-left: 60vh;
    }
    .otherprojects .other-project-img{
        width: 500px;
    }
}
@media only screen and (max-width:2450px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     max-width:930px;
   }
   .Summerhouse2{
     width: 1500px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 980px;
    }
    .Summerhouse5{
        width: 405px;
    }
    .Summerhouse6{
        width: 970px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
    .otherprojects .other-project-img{
        width: 500px;
    }
}
@media only screen and (max-width:2400px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     max-width:900px;
   }
   .Summerhouse2{
     width: 1450px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 980px;
    }
    .Summerhouse5{
        width: 405px;
    }
    .Summerhouse6{
        width: 970px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
    .otherprojects .other-project-img{
        width: 500px;
    }
}
@media only screen and (max-width:2350px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     max-width:880px;
   }
   .Summerhouse2{
     width: 1420px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 970px;
    }
    .Summerhouse5{
        width: 400px;
    }
    .Summerhouse6{
        width: 940px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
    .otherprojects .other-project-img{
        width: 500px;
    }
}
@media only screen and (max-width:2300px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     max-width:865px;
   }
   .Summerhouse2{
     width: 1390px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 940px;
    }
    .Summerhouse5{
        width: 390px;
    }
    .Summerhouse6{
        width: 930px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
    .otherprojects .other-project-img{
        width: 500px;
    }
}
@media only screen and (max-width:2240px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 850px;
   }
   .Summerhouse2{
     width: 1370px;
   }
    .second-container-detailpage{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }  
    .third-contain-first-col{
       height: 80vh;
    }
    .Summerhouse4{
        width: 910px;
    }
    .Summerhouse5{
        width: 382px;
    }
    .Summerhouse6{
        width: 906px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
    .otherprojects .other-project-img{
        width: 500px;
    }
} 
@media only screen and (max-width:2200px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 820px;
   }
   .Summerhouse2{
     width: 1320px;
   }
    .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }  
    .third-contain-first-col{
       height: 80vh;
    }
    .Summerhouse4{
        width: 900px;
    }
    .Summerhouse5{
        width: 373px;
    }
    .Summerhouse6{
        width: 887px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
    .otherprojects .other-project-img{
        width: 500px;
    }
} 
@media only screen and (max-width:2115px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 790px;
   }
   .Summerhouse2{
     width: 1280px;
   }
    .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }  
    .third-contain-first-col{
       height: 80vh;
    }
    .Summerhouse4{
        width: 850px;
    }
    .Summerhouse5{
        width: 350px;
    }
    .Summerhouse6{
        width: 840px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
    .otherprojects .other-project-img{
        width: 500px;
    }
} 
@media only screen and (max-width:2020px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 744px;
   }
   .Summerhouse2{
     width: 1200px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 810px;
    }
    .Summerhouse5{
        width: 338px;
    }
    .Summerhouse6{
        width: 807px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
    .otherprojects .other-project-img{
        width: 500px;
    }
} 
@media screen and (max-width:1925px) {
    .Summerhouse1{
        width: 730px;
      }
      .Summerhouse2{
        width: 1180px;
      }
      .Summerhouse4{
        width: 780px;
    }
    .Summerhouse5{
        width: 320px;
    }
    .Summerhouse6{
        width: 780px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
}
@media screen and (max-width:1910px) {
    .Summerhouse1{
        width: 710px;
      }
      .Summerhouse2{
        width: 1150px;
      }
      .Summerhouse4{
        width: 750px;
    }
    .Summerhouse5{
        width: 310px;
    }
    .Summerhouse6{
        width: 765px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
}
@media screen and (max-width:1830px) {
    .Summerhouse1{
        width: 690px;
      }
      .Summerhouse2{
        width: 1110px;
      }
      .Summerhouse4{
        width: 720px;
    }
    .Summerhouse5{
        width: 300px;
    }
    .Summerhouse6{
        width: 720px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
}
@media only screen and (max-width:1800px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 680px;
   }
   .Summerhouse2{
     width: 1100px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 732px;
    }
    .Summerhouse5{
        width: 304px;
    }
    .Summerhouse6{
        width: 724px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
   
    .otherprojects .other-project-img{
        width: 500px;
    }
} 
@media only screen and (max-width:1750px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 650px;
   }
   .Summerhouse2{
     width: 1050px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 710px;
    }
    .Summerhouse5{
        width: 295px;
    }
    .Summerhouse6{
        width: 700px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
   
    .otherprojects .other-project-img{
        width: 500px;
    }
} 
@media only screen and (max-width:1728px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 650px;
   }
   .Summerhouse2{
     width: 1050px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 700px;
    }
    .Summerhouse5{
        width: 290px;
    }
    .Summerhouse6{
        width: 695px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
   
    .otherprojects .other-project-img{
        width: 500px;
    }
} 
@media only screen and (max-width:1700px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 640px;
   }
   .Summerhouse2{
     width: 1030px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 690px;
    }
    .Summerhouse5{
        width: 280px;
    }
    .Summerhouse6{
        width: 690px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
   
    .otherprojects .other-project-img{
        width: 500px;
    }
} 
@media only screen and (max-width:1640px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 620px;
   }
   .Summerhouse2{
     width: 1000px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 650px;
    }
    .Summerhouse5{
        width: 270px;
    }
    .Summerhouse6{
        width: 640px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
   
    .otherprojects .other-project-img{
        width: 500px;
    }
} 
@media only screen and (max-width:1600px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 600px;
   }
   .Summerhouse2{
     width: 965px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 650px;
    }
    .Summerhouse5{
        width: 270px;
    }
    .Summerhouse6{
        width: 640px;
    }
    .detailproject-col{
        margin-left: 67vh;
    }
    .otherprojects .other-project-img{
        width: 500px;
    }
} 
 @media only screen and (max-width:1540px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 575px;
   }
   .Summerhouse2{
     width: 925px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 610px;
    }
    .Summerhouse5{
        width: 255px;
    }
    .Summerhouse6{
        width: 610px;
    }
   .detailproject-col{
    margin-left: 65vh;
   }
    .otherprojects .other-project-img{
        width: 400px;
    }
}  
 @media only screen and (max-width:1500px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 565px;
   }
   .Summerhouse2{
     width: 910px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 605px;
    }
    .Summerhouse5{
        width: 250px;
    }
    .Summerhouse6{
        width: 605px;
    }
   .detailproject-col{
    margin-left: 65vh;
   }
    .otherprojects .other-project-img{
        width: 400px;
    }
}  
 @media only screen and (max-width:1440px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 545px;
   }
   .Summerhouse2{
     width: 878px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 582px;
    }
    .Summerhouse5{
        width: 241px;
    }
    .Summerhouse6{
        width: 575px;
    }
   .detailproject-col{
    margin-left: 65vh;
   }
    .otherprojects .other-project-img{
        width: 400px;
    }
}  
 @media only screen and (max-width:1400px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 530px;
   }
   .Summerhouse2{
     width: 855px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 560px;
    }
    .Summerhouse5{
        width: 235px;
    }
    .Summerhouse6{
        width: 560px;
    }
   .detailproject-col{
    margin-left: 65vh;
   }
    .otherprojects .other-project-img{
        width: 400px;
    }
}  
 
 @media only screen and (max-width:1370px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 510px;
   }
   .Summerhouse2{
     width: 820px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 547px;
    }
    .Summerhouse5{
        width: 227px;
    }
    .Summerhouse6{
        width: 535px;
    }
   .detailproject-col{
    margin-left: 33vh;
   }
    .otherprojects .other-project-img{
        width: 400px;
    }
}  
@media only screen and (max-width:1344px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 500px;
   }
   .Summerhouse2{
     width: 810px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 535px;
    }
    .Summerhouse5{
        width: 223px;
    }
    .Summerhouse6{
        width: 535px;
    }
   .detailproject-col{
    margin-left: 33vh;
   }
    .otherprojects .other-project-img{
        width: 400px;
    }
} 
 @media only screen and (max-width:1300px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 480px;
   }
   .Summerhouse2{
     width: 775px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 505px;
    }
    .Summerhouse5{
        width: 210px;
    }
    .Summerhouse6{
        width: 500px;
    }
   .detailproject-col{
    margin-left: 33vh;
   }
    .otherprojects .other-project-img{
        width: 400px;
    }
}  
 @media only screen and (max-width:1250px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 470px;
   }
   .Summerhouse2{
     width: 760px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 492px;
    }
    .Summerhouse5{
        width: 210px;
    }
    .Summerhouse6{
        width: 500px;
    }
   .detailproject-col{
    margin-left: 33vh;
   }
    .otherprojects .other-project-img{
        width: 400px;
    }
}  
@media only screen and (max-width:1200px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 450px;
   }
   .Summerhouse2{
     width: 727px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 485px;
    }
    .Summerhouse5{
        width: 200px;
    }
    .Summerhouse6{
        width: 475px;
    }
   .detailproject-col{
    margin-left: 60vh;
}
    .otherprojects .other-project-img{
        width: 300px;
    }
}  
@media only screen and (max-width:1160px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 435px;
   }
   .Summerhouse2{
     width: 700px;
   }
   .second-container-detailpage-Summerhouse{
        height: 120vh;
        margin-top: 30px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 460px;
    }
    .Summerhouse5{
        width: 190px;
    }
    .Summerhouse6{
        width: 460px;
    }
   .detailproject-col{
    margin-left: 60vh;
}
    .otherprojects .other-project-img{
        width: 300px;
    }
}  
@media only screen and (max-width:1100px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 410px;
   }
   .Summerhouse2{
     width: 660px;
   }
   .second-container-detailpage-Summerhouse{
        height: 120vh;
        margin-top: 30px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 440px;
    }
    .Summerhouse5{
        width: 180px;
    }
    .Summerhouse6{
        width: 440px;
    }
   .detailproject-col{
    margin-left: 60vh;
}
    .otherprojects .other-project-img{
        width: 300px;
    }
}  
@media only screen and (max-width:1024px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 385px;
   }
   .Summerhouse2{
     width: 620px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 409px;
    }
    .Summerhouse5{
        width: 170px;
    }
    .Summerhouse6{
        width: 405px;
    }
   .detailproject-col{
    margin-left: 50vh;
}
   .detailproject-col h2{
    font-size: 20px;
   }
   .detailproject-col h6{
    font-size: 14px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 300px;
    }
}  
@media only screen and (max-width:970px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 360px;
   }
   .Summerhouse2{
     width: 580px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 370px;
    }
    .Summerhouse5{
        width: 160px;
    }
    .Summerhouse6{
        width: 380px;
    }
   .detailproject-col{
    margin-left: 50vh;
}
   .detailproject-col h2{
    font-size: 20px;
   }
   .detailproject-col h6{
    font-size: 14px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 300px;
    }
}  
@media only screen and (max-width:900px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 330px;
   }
   .Summerhouse2{
     width: 532px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 40px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 360px;
    }
    .Summerhouse5{
        width: 148px;
    }
    .Summerhouse6{
        width: 352px;
    }
   .detailproject-col{
    margin-left: 48vh;
}
   .detailproject-col h2{
    font-size: 20px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 250px;
    }
}  
@media only screen and (max-width:870px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 320px;
   }
   .Summerhouse2{
     width: 515px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 30px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 340px;
    }
    .Summerhouse5{
        width: 140px;
    }
    .Summerhouse6{
        width: 330px;
    }
   .detailproject-col{
    margin-left: 48vh;
}
   .detailproject-col h2{
    font-size: 20px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 250px;
    }
}  
@media only screen and (max-width:821px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 300px;
   }
   .Summerhouse2{
     width: 480px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 30px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 320px;
    }
    .Summerhouse5{
        width: 130px;
    }
    .Summerhouse6{
        width: 310px;
    }
   .detailproject-col{
    margin-left: 48vh;
}
   .detailproject-col h2{
    font-size: 20px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 250px;
    }
}  
@media only screen and (max-width:768px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 280px;
   }
   .Summerhouse2{
     width: 450px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 30px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 300px;
    }
    .Summerhouse5{
        width: 125px;
    }
    .Summerhouse6{
        width: 303px;
    }
   .detailproject-col{
    margin-left: 35vh;
}
   .detailproject-col h2{
    font-size: 20px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
    }
}  
@media only screen and (max-width:576px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 210px;
   }
   .Summerhouse2{
     width: 340px;
   }
   .second-container-detailpage-Summerhouse{
        height: 150vh;
        margin-top: 30px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 380px;
    }
    .Summerhouse5{
        width: 160px;
    }
    .Summerhouse6{
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
    }
   .detailproject-col{
    margin-left: 35vh;
}
   .detailproject-col h2{
    font-size: 20px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
    }
}  
@media only screen and (max-width:550px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 185px;
   }
   .Summerhouse2{
     width: 298px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 340px;
    }
    .Summerhouse5{
        width: 140px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:500px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 185px;
   }
   .Summerhouse2{
     width: 298px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 340px;
    }
    .Summerhouse5{
        width: 140px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:480px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 180px;
   }
   .Summerhouse2{
     width: 290px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 323px;
    }
    .Summerhouse5{
        width: 135px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:475px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 180px;
   }
   .Summerhouse2{
     width: 290px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 315px;
    }
    .Summerhouse5{
        width: 130px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:470px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 175px;
   }
   .Summerhouse2{
     width: 280px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 315px;
    }
    .Summerhouse5{
        width: 135px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:465px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 175px;
   }
   .Summerhouse2{
     width: 280px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 310px;
    }
    .Summerhouse5{
        width: 130px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:455px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 165px;
   }
   .Summerhouse2{
     width: 265px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 305px;
    }
    .Summerhouse5{
        width: 125px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
        height: 100px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:450px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 165px;
   }
   .Summerhouse2{
     width: 265px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 305px;
    }
    .Summerhouse5{
        width: 125px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
        height: 100px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:445px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 155px;
   }
   .Summerhouse2{
     width: 250px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 285px;
    }
    .Summerhouse5{
        width: 118px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 16px;
        margin-left: 20px;
    }
}  
@media only screen and (max-width:435px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 155px;
   }
   .Summerhouse2{
     width: 250px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 285px;
    }
    .Summerhouse5{
        width: 118px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 16px;
        margin-left: 20px;
    }
}  
@media only screen and (max-width:425px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 158px;
   }
   .Summerhouse2{
     width: 255px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 287px;
    }
    .Summerhouse5{
        width: 118px;
    }
    .Summerhouse6{
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 12px;
   }
   .detailproject-col p{
    font-size: 12px;
   }
    .otherprojects .other-project-img{
        width: 200px;
        margin-top: 20px;
        margin-left: 70px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 16px;
        margin-left: 20px;
    }
}  
@media only screen and (max-width:415px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 145px;
   }
   .Summerhouse2{
     width: 232px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    
    }
    .Summerhouse4{
        width: 260px;
    }
    .Summerhouse5{
        width: 112px;
    }
    .Summerhouse6{
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
    }
   .detailproject-col{
    margin-left: 0vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 10px;
   }
   .detailproject-col p{
    font-size: 10px;
   }
  
    .otherprojects .other-project-img{
        width: 300px;
        margin-top: 20px;
        margin-left: 35px;
    }
    .plus-img{
        width: 30px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:400px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 145px;
   }
   .Summerhouse2{
     width: 232px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    
    }
    .Summerhouse4{
        width: 260px;
    }
    .Summerhouse5{
        width: 112px;
    }
    .Summerhouse6{
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
    }
   .detailproject-col{
    margin-left: 0vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 10px;
   }
   .detailproject-col p{
    font-size: 10px;
   }
  
    .otherprojects .other-project-img{
        width: 300px;
        margin-top: 20px;
        margin-left: 35px;
    }
    .plus-img{
        width: 30px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:385px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 145px;
   }
   .Summerhouse2{
     width: 232px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    
    }
    .Summerhouse4{
        width: 255px;
    }
    .Summerhouse5{
        width: 105px;
    }
    .Summerhouse6{
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
    }
   .detailproject-col{
    margin-left: 0vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 10px;
   }
   .detailproject-col p{
    font-size: 10px;
   }
  
    .otherprojects .other-project-img{
        width: 300px;
        margin-top: 20px;
        margin-left: 35px;
    }
    .plus-img{
        width: 30px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:375px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 140px;
   }
   .Summerhouse2{
     width: 225px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    
    }
    .Summerhouse4{
        width: 250px;
    }
    .Summerhouse5{
        width: 100px;
    }
    .Summerhouse6{
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
    }
   .detailproject-col{
    margin-left: 0vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 10px;
   }
   .detailproject-col p{
    font-size: 10px;
   }
  
    .otherprojects .other-project-img{
        width: 300px;
        margin-top: 20px;
        margin-left: 35px;
    }
    .plus-img{
        width: 30px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:360px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 135px;
   }
   .Summerhouse2{
     width: 215px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    
    }
    .Summerhouse4{
        width: 220px;
    }
    .Summerhouse5{
        width: 95px;
    }
    .Summerhouse6{
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
    }
   .detailproject-col{
    margin-left: 0vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 10px;
   }
   .detailproject-col p{
    font-size: 10px;
   }
  
    .otherprojects .other-project-img{
        width: 300px;
        margin-top: 20px;
        margin-left: 35px;
    }
    .plus-img{
        width: 30px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:350px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 130px;
   }
   .Summerhouse2{
     width: 210px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 20px;
    }
    .detail-page-contain3{
        height: auto;
    
    }
    .Summerhouse4{
        width: 220px;
    }
    .Summerhouse5{
        width: 90px;
    }
    .Summerhouse6{
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
    }
   .detailproject-col{
    margin-left: 0vh;
}
   .detailproject-col h2{
    font-size: 25px;
   }
   .detailproject-col h6{
    font-size: 10px;
   }
   .detailproject-col p{
    font-size: 10px;
   }
  
    .otherprojects .other-project-img{
        width: 300px;
        margin-top: 20px;
        margin-left: 35px;
    }
    .plus-img{
        width: 30px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:336px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 115px;
   }
   .Summerhouse2{
     width: 185px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 30px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 212px;
    }
    .Summerhouse5{
        width: 88px;
    }
    .Summerhouse6{
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 18px;
   }
   .detailproject-col h6{
    font-size: 10px;
   }
   .detailproject-col p{
    font-size: 10px;
   }
    .otherprojects .other-project-img{
        width: 250px;
        margin-top: 20px;
        margin-left: 50px;
        height: 200px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
@media only screen and (max-width:320px){
    .detail-page{
        height: auto;
    }
   .Summerhouse1{
     width: 115px;
   }
   .Summerhouse2{
     width: 185px;
   }
   .second-container-detailpage-Summerhouse{
        height: 60vh;
        margin-top: 30px;
    }
    .detail-page-contain3{
        height: auto;
    }
    .Summerhouse4{
        width: 212px;
    }
    .Summerhouse5{
        width: 88px;
    }
    .Summerhouse6{
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
    }
   .detailproject-col{
    margin-left: 4vh;
}
   .detailproject-col h2{
    font-size: 18px;
   }
   .detailproject-col h6{
    font-size: 10px;
   }
   .detailproject-col p{
    font-size: 10px;
   }
    .otherprojects .other-project-img{
        width: 250px;
        margin-top: 20px;
        margin-left: 50px;
        height: 200px;
    }
    .plus-img{
        width: 20px;
        
    }
    .other-head{
        margin-top: 20px;
        font-size: 22px;
        margin-left: 0px;
    }
}  
