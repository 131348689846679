*{
    padding: 0;
    margin: 0;
}
@font-face {
    font-family: myfont;
    src: url(../../../Fonts/Cristola\ Display.ttf);
}
@font-face {
    font-family: bodyfont;
    src: url(../../../Fonts/DMSans-Medium.ttf);
}
.inner-container-Functionality2{
    margin: 0 auto;
    width: 99%;
}
.Functionality-container2-col1{
    font-family: myfont;
    font-size: 80px;
    font-weight: 500;
}
.Functionality-container2-col2{
  text-align: end;
 
}
.Functionality-container2-col2 img{
    margin-right: 30px;
}
.Functionality-container2-col2 img{
    width: 40%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.inner-container-Functionality2{
    border-bottom: 2px solid black;
}
.inner-container-Functionality3{
    margin: 0 auto;
    width: 99%;
}
.Functionality-container3-col1{
    /* border: 2px solid black; */
}
.Functionality-container3-col1 img{
    margin-top: 10px;
    width: 100%;
}
.Functionality-container3-col2 img{
    width: 100%;
 
}
.Functionality-container3-col2 p{
         text-align: justify;
         font-family: bodyfont2;
}
.functoinality3-img{
    width: 100%;
    margin-top: 45px;
}
.staircase-last-img{
    margin-top: 1px;
}
.journal-para{
    font-family: bodyfont2;
}
/* ........................................................... Media Query ......................................  */
@media only screen and (max-width:2560px){
    .Functionality-container2-col1{
        font-family: myfont;
        font-size: 100px;
        font-weight: 500;
    }
    .tamil-language .Functionality-container2-col1{
        font-size: 70px;
    }
}
@media only screen and (max-width:1600px){
    .Functionality-container2-col1{
        font-family: myfont;
        font-size: 80px;
        font-weight: 500;
    }
}
@media only screen and (max-width:821px){
    .Functionality-container2-col2 img{
        width: 80%;
    }
    .Functionality-container2-col1{
        font-size: 50px;
    }
    .Functionality-container2-col2 img{
        width: 43%;
    }
}
@media only screen and (max-width:500px){
    .Functionality-container2-col1{
        font-size: 25px;
    }
    .Functionality-container2-col2 img{
        width: 80%;
    }
    .Functionality-container3-col2 p{
        font-size: 14px;
    }
}